<template>
  <div id="app">
    <!-- NAV -->
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div class="container">
        <a class="navbar-brand" href="/"><img src="./assets/Innisfree-logo.png" class="img-fluid logo"
            alt="" />Innisfree Manor</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" exact-active-class="exact-active-link" aria-current="page" to="/">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" exact-active-class="exact-active-link" aria-current="page" to="/about">About</router-link>
            </li>
            <li class="nav-item">
              <a class="nav-link" exact-active-class="exact-active-link" aria-current="page" href="/#gallery">Gallery</a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" exact-active-class="exact-active-link" aria-current="page" to="/manor">Manor</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" exact-active-class="exact-active-link" aria-current="page" to="/cottage">Cottage</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" exact-active-class="exact-active-link" aria-current="page" to="/suite1">Suite 1</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" exact-active-class="exact-active-link" aria-current="page" to="/suite2">Suite 2</router-link>
            </li>
            <li class="nav-item">
              <button type="button" class="btn nav-link bg-transparent" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Contact Us
</button>
            </li>
            <li class="nav-item">
              <a class="btn btn-primary nav-link text-white ms-md-5 px-3 fw-bold"
                href="https://book.nightsbridge.com/33152" target="_blank">
                Book Now
              </a>
            </li>
          </ul>
          <!-- <form class="d-flex">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button class="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> -->
        </div>
      </div>
    </nav>
    <!-- NAV -->

    <router-view></router-view>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered p-3">
        <div class="modal-content bg-dark modal-dialog-centered">
          <div class="modal-header d-flex justify-content-between w-100">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Contact Us
            </h5>

            <button type="button" class="btn" style="color:white;font-size:20px" data-bs-dismiss="modal" aria-label="Close">
              <i class="fa-regular fa-circle-xmark"></i>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="sendEmail">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label text-white">Name</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    v-model="name" name="name" />
                  <div id="emailHelp" class="form-text"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row"></div>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label text-white">Phone</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    v-model="phone" name="phone" />
                  <div id="emailHelp" class="form-text"></div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label text-white">Email</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    v-model="email" name="email" />
                  <div id="emailHelp" class="form-text"></div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label text-white">How can we help</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" name="message" rows="3"
                      v-model="message"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" class="form-control btn-primary mt-3 estimate-submit">
              <div v-if="loading" class="spinner-border spinner-border-md"></div>
              <span v-if="loading"> Sending</span>
              <span v-else>{{ title }}</span>
              <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
            </button>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>import emailjs from "emailjs-com";
export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      message: "",
      loading: false,
      title: "Send",
      error: "",
    };
  },
  methods: {
    sendContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        // axios
        //   .post(
        //     "/phpmailer/src/contact.php",
        //     qs.stringify({
        //       name: this.name,
        //       email: this.email,
        //       phone: this.phone,
        //       message: this.message,
        //     })
        //   )
        //   .then((response) => {
        //     this.loading = !false;
        //     this.title = "Message Sent";
        //     setTimeout(() => (this.title = "Send"), 5000);
        //     setTimeout(() => {
        //       this.loading = !true;
        //     }, 2000);
        //     this.name = "";
        //     this.email = "";
        //     this.phone = "";
        //     this.message = "";
        //     console.log(response);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });
      }
    },
    sendEmail(e) {
      if (this.fullname === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        try {
          emailjs.sendForm(
            "service_kgyrbx9",
            "template_qwp7e19",
            e.target,
            "2XiCmqxKy-txAQA1Z",
            {
              from_name: this.fullname,
              name: this.fullname,
              email: this.email,
              message: this.message,
              phone: this.phone,
              reply_to: this.email,
            }
          );
          this.loading = !false;
          this.title = "Message Sent";
          setTimeout(() => (this.title = "Send"), 5000);
          setTimeout(() => {
            this.loading = !true;
          }, 2000);
          this.fullname = "";
          this.email = "";
          this.phone = "";
          this.message = "";
        } catch (error) {
          console.log({ error });
        }
        // Reset form field
        this.name = "";
        this.email = "";
        this.phone = "";
        this.message = "";
      }
    },
  },
};
</script>


<style>
:root {
  --blue: #14345b;
  --blue-muted: #39495e;
  --dark-blue: #002499;
}

.btn-blue {
  background: var(--blue);
  border-radius: 7px;
}

.btn-blue:hover {
  background: var(--dark-blue);
}

.bg-blue {
  background-color: var(--blue);
}

html {
  font-size: 10px;
  color: var(--blue);
  font-family: "Open Sans", sans-serif;
}

.display-1 {
  font-size: 9.5rem;
  text-shadow: 1px 1px 3px black, 1px 1px 6px rgba(0, 0, 0, 0.459);
}

h1,
.h1 {
  font-size: 4.5rem;
}

h2,
.h2 {
  font-size: 4rem;
}

h3,
.h3 {
  font-size: 3.5rem;
}

h4,
.h4 {
  font-size: 2.3rem;
}

h5,
.h5 {
  font-size: 1.8rem;
}

p,
.p {
  font-size: 1.7rem;
}

.logo {
  width: 40px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.navbar-nav .nav-link {
  font-size: 1.8rem;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.nav-link:hover {
  font-size: 1.8rem;
}

.navbar-brand {
  font-size: 3rem;
}

.navbar-brand:hover {
  font-size: 3rem;
}

a {
  text-decoration: none;
}

.form-label {
  font-size: 1.7rem;
}

.form-control {
  font-size: 1.7rem;
}

.form-check-input {
  font-size: 1.7rem;
  height: 15px;
  width: 15px;
  margin-left: 15px;
  margin-right: 7px;
}

.pt-sml {
  padding-top: 50px;
}

.pt-lrg {
  padding-top: 100px;
}

.pb-lrg {
  padding-bottom: 100px;
}

.pt-xlrg {
  padding-top: 200px;
}

.mt-sml {
  margin-top: 50px;
}

.mt-lrg {
  margin-top: 100px;
}

.mt-xlrg {
  margin-top: 200px;
}

.mt-neg-lg {
  margin-top: -100px;
}

.home-hero {
  padding: 0;
  margin: 0;
}

.home-hero-images {
  height: 90vh;
}

.hero-main {
  background: url("./assets/Innisfree-main-house-pool-01.webp");
  background-size: cover;
  background-position: center center;
  height: 100%;
}

.hero-sub {
  height: 46vh;
}

.hero-sub1 {
  background: url("./assets/Large-Suite-2-5.webp");
  background-size: cover;
  background-position: center center;
  height: 96%;
  width: 48%;
  margin: 5px;
}

.hero-sub2 {
  background: url("./assets/gallery-01.webp");
  background-size: cover;
  background-position: center center;
  height: 96%;
  width: 48%;
  margin: 5px;
}

.hero-sub3 {
  background: url("./assets/gallery-02.webp");
  background-size: cover;
  background-position: center center;
  height: 96%;
  width: 48%;
  margin: 5px;
}

.hero-sub4 {
  background: url("./assets/gallery-03.webp");
  background-size: cover;
  background-position: center center;
  height: 96%;
  width: 48%;
  margin: 5px;
}

.grid {
  display: grid;
  grid-template-rows: repeat(4, 180px);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}

.item:nth-child(1) {
  grid-row: 1 / 4;
}

.item:nth-child(2) {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

.item:nth-child(3) {
  grid-column: 3 / 4;
  grid-row: 3 / 5;
}

.item:nth-child(4) {
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

.item:nth-child(5) {
  grid-column: 1 / 3;
  grid-row: 4 / 5;
}

/* styling code */

.grid {
  margin: 20px auto;
  max-width: 1000px;
}

.grid>.item {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gal1 {
  background-image: url("./assets/Innisfree-img48.webp");
}

.gal2 {
  background-image: url("./assets/Innisfree-img3.webp");
}

.gal3 {
  background-image: url("./assets/Innisfree-img1.webp");
}

.gal4 {
  background-image: url("./assets/Innisfree-img2.webp");
}

.gal5 {
  background-image: url("./assets/Innisfree-img28.webp");
}


.lb-grid {
  height: 100vh;
}

section {
  scroll-margin-top: 80px;
}

.showcase {
  filter: grayscale(100%);
  transition: 0.6s;
}

.showcase:hover {
  filter: grayscale(0%);
  transition: 0.6s;
}

.activeImage {
  filter: grayscale(0%) !important;
  transition: 0.6s;
}
.exact-active-link {
  color: white !important;
  font-weight: bold; /* Custom highlight color for the exactly matched active link */
}
</style>
