<template>
  <div class="home">
    <section class="bg-white pt-lrg pb-sml" id="about">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="pb-3">Welcome to Innisfree Manor</h1>
            <p>
              Nestled high on Schapenberg Mountain, surrounded by vineyards, yet
              with breathtaking views of False Bay, Table Mountain and the
              Helderberg, Innisfree is little piece of heaven on earth. All
              guest accommodation at the Manor offers stylish, private
              accommodation, beautiful private entertainment areas and
              unparalleled comfort, in an exquisite garden with an enormous
              heated solar pool. All rooms are fully equipped with smart
              televisions offering Netflix and full DSTV.
            </p>
            <p>
              Accommodation options range from the fully equipped Innisfree
              Cottage, with two bedrooms, a full kitchen, private bathroom, full
              lounge, kitchen and huge private outdoor area with gas and wood
              braai options. The cottage can sleep up to five people.
            </p>
            <p>
              Other options available at Innisfree Manor are two exclusive guest
              suites. Each room has an en-suite bathroom, private braai patio with Microwave,bar
              fridge kettle and mini stove. The guest suites are fully serviced. Suite 1 can sleep 2 people and Suite 2
              can sleep up to 3 people.
            </p>

            <p>
              Equipped with an
              <span class="text-secondary fw-bolder">inverter</span> and
              <span class="text-secondary fw-bolder">gas stoves</span>, all
              accommodation options are geared up make sure your stay is as
              amazing as the stunning view.
            </p>
            <p>
              Innisfree is located close to Vergelegen Medi-clinic and all
              accommodation options are highly popular for their privacy and
              comfort for those who require a place stay due to medical
              procedures.
            </p>
            <p>
              Innisfree is close to
              <a href="https://www.erinvale.com/" target="_blank" class="text-secondary fw-bolder">Erinvale Golf
                Estate</a>, 
              <a href="" target="_blank" class="text-secondary fw-bolder">Morgenster, </a>
              <a href="https://lourensford.co.za/" target="_blank" class="text-secondary fw-bolder">Lourensford, </a>
              <a href="https://vergelegen.co.za/" target="_blank" class="text-secondary fw-bolder">Vergelegen, </a>
              <a href="https://idiom.co.za/" target="_blank" class="text-secondary fw-bolder">Idiom, </a>
              <a href="https://www.waterkloofwines.co.za/" target="_blank" class="text-secondary fw-bolder">waterkloof </a>
              Wineries.
            </p>
            <p>
              Innisfree Manor, main house, consists of 4 bedrooms, 3 bathrooms,
              lavish entertainment areas and a state-of-the-art kitchen. Boasts
              stunning views of table mountain, false bay and the Helderberg.All
              bedrooms have tvs.
            </p>
            <p>
              <a href="#contact" class="text-secondary fw-bolder">Please contact Innisfree</a>
              for special rates and long stay discounts as well as any further
              information you may need.
            </p>
          </div>
          <div class="col-md-12 mt-5" id="gallery" v-if="show == 'home'">
            <VueGallery></VueGallery>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white pt-lrg pb-sml" id="about" v-if="show == 'home'">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h1 class="pb-3">Meet Bentley and Roxie, the Innisfree Manor fur hosts</h1>
            <p>
              Innisfree Guesthouse, set on an expansive garden, with plenty of outside garden "rooms" to explore, is a children's and pets paradise! Bentley, our cute and playful chocolate Labrador, and Roxie, our timid but loving South African "original" rescue dog, are our charming guesthouse fur hosts. At Innisfree, we delight in children guests of all ages who love our solar heated swimming pool. Roxie and Bentley are happy to share their toys and toy box with their human and furry friends, and absolutely love to having company to play! Please note that there is no charge for fur guests. Children under 12 stay free too! 
            </p>
            
          </div>
          <div class="col-md-12 mt-5" id="gallery" v-if="show == 'home'">
            <div class="row row-cols-1 row-cols-md-2 g-4 border-none pb-5">
  
  <div class="card">
    <div class="card-header">
       <img src="../assets/Innisfree-Bentley-New.webp" class="card-img-top" alt="Innis Free Bentley">
    </div>
    <div class="card-body">
      <div class="card-title text-center mx-auto my-auto" id="badge"> <h4 class="text-dark">My Name Is</h4> <br><h2 class="h2 text-white">Bentley</h2></div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
       <img src="../assets/Innisfree-Roxie.webp" class="card-img-top" alt="Innis Free Roxie">
    </div>
    <div class="card-body">
      <div class="card-title text-center mx-auto my-auto" id="badge"> <h4 class="text-dark">My Name Is</h4> <br><h2 class="h2 text-white">Roxie</h2></div>
    </div>
  </div>
</div>
          </div>
        </div>
      </div>
    </section>
    <footer class="bg-dark py-5" id="contact">
      <MainFooter></MainFooter>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import VueGallery from "@/components/VueGallery.vue";

export default {
  name: "HomeView",
  components: { VueGallery: () => import("@/components/VueGallery.vue") },
  data() {
    return {
      show: "home",
      cottageImg: 1,
      rooms: {
        manor: {
          title: "Innisfree Manor",
          img: "../images/Innisfree-manor-1.webp",
          url: "/manor",
          description:
            "Guests have use of the entire house and designer open plan kitchen. The stunning 12 by 8 metre solar heated pool is great for keeping fit and for fun. The outdoor jacuzzi and sauna are also available.",
          amenities1: [
            "Bathroom amenities",
            "Wake-up calls",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Air conditioning",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Desk",
            "Hairdryer",
            "Full Kitchen",
            "Iron",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: [
            "Sitting area",
            "Patio",
            "Non-smoking",
            "Bathtub/shower",
            "Jacuzzi",
            "Sauna",
            "Heated Pool",
          ],
          Guests: "10 (Max 10 adults )",
        },
        cottage: {
          title: "Innisfree Cottage",
          img: "../images/Innisfree-cottage-1-2.webp",
          url: "/cottage",
          description:
            "Innisfree Cottage has two bedrooms. Bedroom 1 has a king-size bed and the second bedroom can be configured as either a twin or king option. The cottage has Wi-Fi, Netflix, and DStv. There is a large well-appointed bathroom and fully equipped kitchen.",
          amenities1: [
            "Bathroom amenities",
            "Wake-up calls",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Air conditioning",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Desk",
            "Hairdryer",
            "Full Kitchen",
            "Iron",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: ["Sitting area", "Patio", "Non-smoking", "Shower only"],
          Guests: "5 (Max 5 adults )",
        },
        suite1: {
          title: "Innisfree Large Suite 1",
          img: "../images/Innisfree-suite1-1.webp",
          url: "/suite1",
          description:
            "Suite 1 is exclusively a guest suite, offering accommodation for 1 to 2 people. The Suite has an en-suite bathroom and a private braai patio with cooking facilities. The suite is fully serviced.",
          amenities1: [
            "Bathroom amenities",
            "Wake-up calls",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Air conditioning",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Maid service",
            "Desk",
            "Hairdryer",
            "Kitchenette",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: [
            "Sitting area",
            "Patio",
            "Non-smoking",
            "Bathtub/shower",
          ],
          Guests: " 3 (Max 2 adults )",
        },
        suite2: {
          title: "Innisfree Large Suite 2",
          img: "../images/Innisfree-suite2-1.webp",
          url: "/suite2",
          description:
            "Stunning garden cottage with private bathroom, private bathroom, courtyard, breakfast and cooking facilities. Sleeps up to 3 guests..",
          amenities1: [
            "Bathroom amenities",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Maid service",
            "Hairdryer",
            "Kitchenette",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: ["Sitting area", "Patio", "Shower only"],
          Guests: " 2 (Max 2 adults )",
        },
      },
    };
  },
};
</script>

<style scoped>
#badge {
  background-color: #4a4a4a;
  width: 250px;
  height: 150px;
  border-radius: 20px;
  border: 8px solid #212529;
  margin: 1em;
  padding-top: 20px;
}
.card {
  border: none;
}
</style>