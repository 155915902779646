<template>
  <div class="vh-100">
    <div class="row mt-30">
      <div class="col-12 h-100">
        <lightbox class="h-100" :items="images" :cells="5"></lightbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        "./images/Innisfree-gal-pool.webp",
        "./images/Innisfree-gal-01.webp",
        "./images/Innisfree-gal-02.webp",
        "./images/Innisfree-gal-03.webp",
        "./images/Innisfree-gal-04.webp",
        "./images/Innisfree-gal-05.webp",
        "./images/Innisfree-gal-06.webp",
        "./images/Innisfree-gal-07.webp",
        "./images/Innisfree-gal-08.webp",
        "./images/Innisfree-gal-09.webp",
        "./images/Innisfree-gal-10.webp",
        "./images/Innisfree-gal-11.webp",
        "./images/Innisfree-gal-12.webp",
        "./images/Innisfree-gal-13.webp",
        "./images/Innisfree-gal-14.webp",
        "./images/covers-01.webp",
        "./images/gallery-01.webp",
        "./images/gallery-02.webp",
        "./images/gallery-03.webp",
        "./images/Innisfree-suite-1-01.webp",
        "./images/Innisfree-suite-1-02.webp",
        "./images/Innisfree-suite-1-03.webp",
        "./images/Innisfree-suite-1-04.webp",
        "./images/Innisfree-suite-2-01.webp",
        "./images/Innisfree-suite-2-02.webp",
        "./images/Innisfree-suite-2-03.webp",
        "./images/Innisfree-suite-2-04.webp",
        "./images/Innisfree-manor-03.webp",
        "./images/Innisfree-manor-01.webp",
        "./images/Innisfree-manor-02.webp",
        "./images/Innisfree-manor-04.webp",
      ],
    };
  },
};
</script>
