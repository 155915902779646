<template>
  <div class="footer">
    <div class="container py-5">
      <div class="row text-white">
        <h2 class="fw-bold pb-4 text-white h1">Get in touch</h2>
        <div class="col-md-6">
          <div class="row">
            <p class="text-white">We look forward to hearing from you.</p>
            <div class="col-md-2 p">Phone:</div>
            <div class="col-md-10 pb-4">
              <a class="text-white text-decoration-none p" href="tel:+27846763261">+27 84 676 32 61 (Lisa)</a><br>
              <a class="text-white text-decoration-none p" href="tel:+27845859978">+27 84 585 99 78 (Angela)</a><br>
              <a class="text-white text-decoration-none p" href="tel:+27846763261">+27 84 557 20 04 (Ben)</a>
            </div>

            <div class="col-md-2 p">Email:</div>
            <div class="col-md-10 pb-4">
              <a class="text-white text-decoration-none p"
                href="mailto:bookings@innisfree-manor.co.za">bookings@innisfree-manor.co.za</a>
            </div>
            <div class="col-md-2 p">Social:</div>
            <div class="col-md-10 text-white social-icons mb-4">
              <a href="https://www.facebook.com/InnisfreeManor" class="h4" target="_blank"><i
                  class="fa-brands fa-facebook-f text-white me-5 p"></i></a><a
                href="https://www.instagram.com/innisfreemanor/" class="h4" target="_blank"><i
                  class="fa-brands fa-instagram text-white me-5 p"></i></a><a href="https://wa.me/27846763261"
                class="h4" target="_blank"><i class="fa-brands fa-whatsapp text-white me-5 p"></i></a>
            </div>
            <div class="col-md-2 p">Address:</div>
            <div class="col-md-10 pb-4">
              <a class="text-white text-decoration-none p" href="https://goo.gl/maps/kA26TveLvtyFNR4SA" target="_blank"
                rel="noopener noreferrer">21 Cassius Cres<br />
                Rome Glen<br />
                Cape Town<br />
                7130</a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <form @submit.prevent="sendEmail">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Name</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    v-model="name" name="name" />
                  <div id="emailHelp" class="form-text"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row"></div>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Phone</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    v-model="phone" name="phone" />
                  <div id="emailHelp" class="form-text"></div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Email</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    v-model="email" name="email" />
                  <div id="emailHelp" class="form-text"></div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">How can we help</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" name="message" rows="3"
                      v-model="message"></textarea>
                  </div>
                </div>
              </div>
            </div>

            <button type="submit" class="form-control btn-primary mt-3 estimate-submit">
              <div v-if="loading" class="spinner-border spinner-border-md"></div>
              <span v-if="loading"> Sending</span>
              <span v-else>{{ title }}</span>
              <!-- <h5 className="pt-3 text-uppercase">{{ title }}</h5> -->
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
// import qs from "qs";
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      message: "",
      loading: false,
      title: "Send",
      error: "",
    };
  },
  methods: {
    sendContact() {
      if (this.name === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        // axios
        //   .post(
        //     "/phpmailer/src/contact.php",
        //     qs.stringify({
        //       name: this.name,
        //       email: this.email,
        //       phone: this.phone,
        //       message: this.message,
        //     })
        //   )
        //   .then((response) => {
        //     this.loading = !false;
        //     this.title = "Message Sent";
        //     setTimeout(() => (this.title = "Send"), 5000);
        //     setTimeout(() => {
        //       this.loading = !true;
        //     }, 2000);
        //     this.name = "";
        //     this.email = "";
        //     this.phone = "";
        //     this.message = "";
        //     console.log(response);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });
      }
    },
    sendEmail(e) {
      if (this.fullname === "" || this.email === "") {
        this.error = "Please fill out all fields!";
        setTimeout(() => (this.error = ""), 5000);
      } else {
        try {
          emailjs.sendForm(
            "service_kgyrbx9",
            "template_qwp7e19",
            e.target,
            "2XiCmqxKy-txAQA1Z",
            {
              from_name: this.fullname,
              name: this.fullname,
              email: this.email,
              message: this.message,
              phone: this.phone,
              reply_to: this.email,
            }
          );
          this.loading = !false;
          this.title = "Message Sent";
          setTimeout(() => (this.title = "Send"), 5000);
          setTimeout(() => {
            this.loading = !true;
          }, 2000);
          this.fullname = "";
          this.email = "";
          this.phone = "";
          this.message = "";
        } catch (error) {
          console.log({ error });
        }
        // Reset form field
        this.name = "";
        this.email = "";
        this.phone = "";
        this.message = "";
      }
    },
  },
};
</script>
