import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import Manor from "../views/Manor.vue";
import Cottage from "../views/Cottage.vue";
import Suite1 from "../views/Suite1.vue";
import Suite2 from "../views/Suite2.vue";
import "@morioh/v-lightbox/dist/lightbox.css";
import Lightbox from "@morioh/v-lightbox";

Vue.use(VueRouter);
Vue.use(Lightbox);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/manor",
    name: "manor",
    component: Manor,
  },
  {
    path: "/cottage",
    name: "cottage",
    component: Cottage,
  },
  {
    path: "/suite1",
    name: "suite1",
    component: Suite1,
  },
  {
    path: "/suite2",
    name: "suite2",
    component: Suite2,
  },
 
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active-link', // Custom global active class
  linkExactActiveClass: 'exact-active-link', // Custom global exact active class
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
