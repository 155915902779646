<template>
  <div class="home">
    <section id="manor1" v-if="show == 'manor1'">
      <div class="container pb-5">
        <div class="row pt-lrg">
          <div class="col-md-6">
            <h1 class="pb-3">Innisfree manor</h1>
            <img v-if="manorImg == 1" src="../assets/Innisfree-manor-02.webp" class="img-fluid" alt="" />
            <img v-if="manorImg == 2" src="../assets/Innisfree-manor-01.webp" class="img-fluid" alt="" />
            <img v-if="manorImg == 3" src="../assets/Innisfree-manor-03.webp" class="img-fluid" alt="" />
            <img v-if="manorImg == 4" src="../assets/Innisfree-manor-04.webp" class="img-fluid" alt="" />
            <div class="row">
              <button class="btn col-md-3 showcase" v-if="manorImg != 1" @click="manorImg = 1">
                <img src="../assets/Innisfree-manor-02.webp" class="img-fluid" alt="" />
              </button>

              <!-- active -->
              <button class="btn col-md-3 showcase activeImage" v-if="manorImg == 1" @click="manorImg = 1">
                <img src="../assets/Innisfree-manor-02.webp" class="img-fluid" alt="" />
              </button>

              <button class="btn col-md-3 showcase" v-if="manorImg != 2" @click="manorImg = 2">
                <img src="../assets/Innisfree-manor-01.webp" class="img-fluid" alt="" />
              </button>

              <!-- active -->
              <button class="btn col-md-3 showcase activeImage" v-if="manorImg == 2" @click="manorImg = 2">
                <img src="../assets/Innisfree-manor-01.webp" class="img-fluid" alt="" />
              </button>

              <button class="btn col-md-3 showcase" v-if="manorImg != 3" @click="manorImg = 3">
                <img src="../assets/Innisfree-manor-03.webp" class="img-fluid" alt="" />
              </button>

              <!-- active -->
              <button class="btn col-md-3 showcase activeImage" v-if="manorImg == 3" @click="manorImg = 3">
                <img src="../assets/Innisfree-manor-03.webp" class="img-fluid" alt="" />
              </button>

              <button class="btn col-md-3 showcase" v-if="manorImg != 4" @click="manorImg = 4">
                <img src="../assets/Innisfree-manor-04.webp" class="img-fluid" alt="" />
              </button>

              <!-- active -->
              <button class="btn col-md-3 showcase activeImage" v-if="manorImg == 4" @click="manorImg = 4">
                <img src="../assets/Innisfree-manor-04.webp" class="img-fluid" alt="" />
              </button>
            </div>
          </div>
          <div class="col-md-6">
            <div class="d-flex justify-content-end">
              <a href="https://book.nightsbridge.com/33152" target="_blank" class="btn btn-primary h4 fw-bold mt-3">Make
                A Booking</a>
            </div>
            <h4>Room description:</h4>
            <p>
              {{ rooms.manor.description }}
            </p>
            <!-- <h4 class="pb-5">R1600/night</h4> -->
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <h4>Bedrooms</h4>
                      <div class="card rounded col-md-6 col-12">
                        <div class="card-body">
                          <h5 class="card-title">
                            <img src="../assets/double-bed.svg" style="height: 40px" class="img-fluid" alt="" />
                          </h5>
                          <h5 class="card-subtitle my-4 text-dark text-uppercase">
                            Bedroom 1
                          </h5>
                          <p class="card-text">1 King Size Bed</p>
                        </div>
                      </div>
                      <div class="card rounded col-md-6 col-12">
                        <div class="card-body">
                          <h5 class="card-title">
                            <img src="../assets/double-bed.svg" style="height: 40px" class="img-fluid" alt="" />
                            <img src="../assets/single-bed.svg" class="img-fluid pt-4" style="height: 40px" alt="" />
                          </h5>
                          <h5 class="card-subtitle my-4 text-dark text-uppercase">
                            Bedroom 2
                          </h5>
                          <p class="card-text">1 Queen Size Bed + Single Bed</p>
                        </div>
                      </div>
                      <div class="card rounded col-md-6 col-12">
                        <div class="card-body">
                          <h5 class="card-title">
                            <img src="../assets/double-bed.svg" style="height: 40px" class="img-fluid" alt="" />
                            <img src="../assets/single-bed.svg" class="img-fluid pt-4" style="height: 40px" alt="" />
                          </h5>
                          <h5 class="card-subtitle my-4 text-dark text-uppercase">
                            Bedroom 3
                          </h5>
                          <p class="card-text">1 Queen Size Bed + Single Bed</p>
                        </div>
                      </div>
                      <div class="card rounded col-md-6 col-12">
                        <div class="card-body">
                          <h5 class="card-title">
                            <img src="../assets/double-bed.svg" style="height: 40px" class="img-fluid" alt="" />
                          </h5>
                          <h5 class="card-subtitle my-4 text-dark text-uppercase">
                            Bedroom 4
                          </h5>
                          <p class="card-text">1 Queen Size Bed</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row pt-5">
                      <h4>Facilities</h4>
                      <div class="card rounded col-md-4 col-12">
                        <div class="card-body">
                          <h5 class="card-title pt-4">
                            <img src="../assets/braai.svg" class="img-fluid" style="height: 45px" alt="" />
                          </h5>
                          <h5 class="card-subtitle my-4 text-dark text-uppercase">
                            Braai Area
                          </h5>
                        </div>
                      </div>
                      <div class="card rounded col-md-4 col-12">
                        <div class="card-body">
                          <h5 class="card-title pt-4">
                            <img src="../assets/pool.svg" class="img-fluid" style="height: 45px" alt="" />
                          </h5>
                          <h5 class="card-subtitle my-4 text-dark text-uppercase">
                            Swimming Pool
                          </h5>
                        </div>
                      </div>
                      <div class="card rounded col-md-4 col-12">
                        <div class="card-body">
                          <h5 class="card-title pt-4">
                            <img src="../assets/pet.svg" class="img-fluid" style="height: 45px" alt="" />
                          </h5>
                          <h5 class="card-subtitle my-4 text-dark text-uppercase">
                            Pet Friendly
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row"></div>
            <div class="row">
              <div class="card rounded col-md-4 col-12">
                <div class="card-body">
                  <h5 class="card-title pt-4">
                    <img src="../assets/netflix.svg" class="img-fluid" style="height: 35px" alt="" />
                  </h5>
                </div>
              </div>
              <div class="card rounded col-md-4 col-12">
                <div class="card-body">
                  <h5 class="card-title pt-4">
                    <img src="../assets/dstv.svg" class="img-fluid" style="height: 35px" alt="" />
                  </h5>
                </div>
              </div>
              <div class="card rounded col-md-4 col-12">
                <div class="card-body">
                  <h5 class="card-title pt-4">
                    <img src="../assets/wifi2.svg" class="img-fluid" style="height: 35px" alt="" />
                  </h5>
                </div>
              </div>

              <h4 class="pt-5">Amenities:</h4>
              <div class="row ps-5">
                <ul v-for="(items1, i) in rooms.manor.amenities1" :key="i" class="col-md-4">
                  <li class="p">{{ items1 }}</li>
                </ul>
                <ul v-for="(items2, i) in rooms.manor.amenities2" :key="i" class="col-md-4">
                  <li class="p">{{ items2 }}</li>
                </ul>
                <ul v-for="(items3, i) in rooms.manor.amenities3" :key="i" class="col-md-4">
                  <li class="p">{{ items3 }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="bg-dark py-5" id="contact">
      <MainFooter></MainFooter>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import VueGallery from "@/components/VueGallery.vue";

export default {
  name: "HomeView",
  data() {
    return {
      show: "manor1",
      manorImg: 1,
      rooms: {
        manor: {
          title: "Innisfree Manor",
          description:
            "Guests have use of the entire house and designer open plan kitchen. The stunning 12 by 8 metre solar heated pool is great for keeping fit and for fun. The outdoor jacuzzi and sauna are also available.",
          amenities1: [
            "Bathroom amenities",
            "Wake-up calls",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Desk",
            "Hairdryer",
            "Full Kitchen",
            "Iron",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: [
            "Sitting area",
            "Patio",
            "Non-smoking",
            "Bathtub/shower",
            "Jacuzzi",
            "Sauna",
            "Heated Pool",
          ],
          Guests: "10 (Max 10 adults )",
        },
        cottage: {
          title: "Innisfree Cottage",
          description:
            "Innisfree Cottage has two bedrooms. Bedroom 1 has a king-size bed and the second bedroom can be configured as either a twin or king option. The cottage has Wi-Fi, Netflix, and DStv. There is a large well-appointed bathroom and fully equipped kitchen.",
          amenities1: [
            "Bathroom amenities",
            "Wake-up calls",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Air conditioning",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Desk",
            "Hairdryer",
            "Full Kitchen",
            "Iron",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: ["Sitting area", "Patio", "Non-smoking", "Shower only"],
          Guests: "5 (Max 5 adults )",
        },
        suite1: {
          title: "Innisfree Large Suite 1",
          description:
            "Suite 1 is exclusively a guest suite, offering accommodation for 1 to 2 people. The Suite has an en-suite bathroom and a private braai patio with cooking facilities. The suite is fully serviced.",
          amenities1: [
            "Bathroom amenities",
            "Wake-up calls",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Air conditioning",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Maid service",
            "Desk",
            "Hairdryer",
            "Kitchenette",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: [
            "Sitting area",
            "Patio",
            "Non-smoking",
            "Bathtub/shower",
          ],
          Guests: " 3 (Max 2 adults )",
        },
        suite2: {
          title: "Innisfree Large Suite 2",
          description:
            "Stunning garden cottage with private bathroom, private bathroom, courtyard, breakfast and cooking facilities. Sleeps up to 3 guests..",
          amenities1: [
            "Bathroom amenities",
            "Wireless internet connection",
            "Coffee/Tea maker",
            "Colour Television",
            "DSTV/ Satellite television",
          ],
          amenities2: [
            "Maid service",
            "Hairdryer",
            "Kitchenette",
            "Microwave",
            "Refrigerator",
          ],
          amenities3: ["Sitting area", "Patio", "Shower only"],
          Guests: " 2 (Max 2 adults )",
        },
      },
    };
  },
};
</script>
