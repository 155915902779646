import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueGallery from "./components/VueGallery.vue";
import MainFooter from "./components/MainFooter.vue";

Vue.config.productionTip = false;

Vue.component("VueGallery", VueGallery);
Vue.component("MainFooter", MainFooter);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
